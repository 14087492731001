<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="PasswordRules"
      tag="form"
    >
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="Old Password"
            label-for="account-old-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Old Password"
              rules="required"
            >
              <b-form-group
                :state="errors.length > 0 ? false:null"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label="New Password"
            label-for="reset-password-new"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="reset-password-new"
                  v-model="newPasswordValue"
                  :type="passwordFieldTypeNew"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="reset-password-new"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconNew"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- <b-form-group
            label-for="account-new-password"
            label="New Password"
          >
            <small>Require at least 1 Uppercase, 1 special character (6-20).</small>
            <validation-provider
              #default="{ errors }"
              name="NewPassword"
              :rules="{regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^*-]).{6,20}$/}"
            >
              <b-form-group
                :state="errors.length > 0 ? false:null"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

          </b-form-group> -->
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="reset-password-confirm"
            label="Confirm Password"
          >
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="reset-password-confirm"
                  v-model="RetypePassword"
                  :type="passwordFieldTypeRetype"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false:null"
                  name="reset-password-confirm"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconRetype"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- <b-form-group
            label-for="account-retype-new-password"
            label="Retype New Password"
          >
            <validation-provider
              #default="{ errors }"
              name="Retype New Password"
              rules="required|confirmed:NewPassword"
            >
              <small>Confirm the new password.</small>
              <b-form-group
                :state="errors.length > 0 ? false:null"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

          </b-form-group> -->
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1"
            @click="updatepassword()"
          >
            Save changes
          </b-button>&nbsp;
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  data() {
    return {
      required,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    updatepassword() {
      this.$refs.PasswordRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          axios.put(`user/password/change/${window.btoa(this.$store.state.user.AppActiveUser.Email)}/${window.btoa(this.passwordValueOld)}/${window.btoa(this.newPasswordValue)}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Password has been changed.').then(() => {
              this.passwordValueOld = ''
              this.newPasswordValue = ''
              this.RetypePassword = ''
            })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Cannot change password.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        } else {
          this.DisplayError('Password cannot be changed.', 'Please check data requirements.')
        }
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
