<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <font-awesome-icon
          :icon="['fas', 'user']"
          class="mr-50"
          size="lg"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <font-awesome-icon
          :icon="['fas', 'lock']"
          class="mr-50"
          size="lg"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import AccountSettingGeneral from './MyAccountGeneral.vue'
import AccountSettingPassword from './MyAccountPassword.vue'

export default {
  components: {
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  data() {
    return {
      options: {},
    }
  },
  mounted() {
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      this.LoadingEnd()
    },
  },
}
</script>
